/* src/Components/Footer/Footer.css */

@import '../../styles/variables.css';

/* Footer Container */
.footer {
  background-color: var(--navbar-bg-color); /* Match navbar color */
  width: 100%;
  padding: 10px 40px; /* Match navbar padding */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px; /* Ensure content does not exceed 1200px */
  margin: 0 auto; /* Center the footer within the viewport */
}

/* Ensure content does not exceed 1200px */
.footer-main,
.footer-subscribe {
  max-width: 1200px;
  width: 100%;
}

/* Subscribe Section */
.footer-subscribe {
  display: flex;
  align-items: center;
  width: 100%; /* Full width within the footer */
  padding: 0; /* Remove padding to eliminate white box */
  background-color: transparent; /* Remove white background */
  flex-shrink: 0;
}

.subscribe-image {
  width: 300px; /* Increased size for better visibility */
  height: auto;
  margin-right: 40px; /* Space between image and content */
  border-radius: 15px; /* Rounded corners */
}

.subscribe-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text and button to the left */
}

.subscribe-title {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--navbar-text-color);
  text-align: left;
}

.subscribe-text {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555555;
  text-align: left;
}

.subscribe-button {
  padding: 12px 24px;
  color: var(--icon-color);
  background-color: var(--dropdown-hover-bg);
  box-shadow: 0 4px 12px rgba(29, 11, 11, 0.54);
  border: var(--button-border);
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.subscribe-button:hover {
  background-color: var(--button-hover-bg);
  color: var(--navbar-hover-text);
}

/* Black Dividing Line */
.footer-divider {
  width: 100%;
  border: none;
  border-top: 2px solid #000000;
  margin: 30px 0;
}

/* Logo and Links Section */
.footer-main {
  display: flex;
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Vertically center the logo and links */
  flex-wrap: nowrap; /* Prevent wrapping */
  width: 100%;
  margin-bottom: 30px;
  overflow: hidden; /* Hide overflow if content exceeds width */
}

/* Company Logo and Name */
.footer-company {
  display: flex;
  align-items: center;
  margin-right: 50px; /* Space between logo and links */
  flex-shrink: 0; /* Prevent shrinking */
}

.footer-logo-link {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 60px; /* Adjust size as needed */
  height: auto;
  margin-right: 20px; /* Space between logo and name */
  transition: transform 0.3s ease; /* Add animation */
}

.company-logo:hover {
  transform: scale(1.05); /* Hover effect */
}

.company-name {
  font-size: 22px;
  font-weight: bold;
  color: var(--navbar-text-color);
  text-decoration: none; /* Remove underline */
}

.company-name:hover {
  color: var(--navbar-hover-text);
}

/* Footer Links */
.footer-links {
  display: flex;
  justify-content: flex-start; /* Align links to the start */
  flex-wrap: nowrap; /* Prevent wrapping */
  flex: 1;
  overflow: hidden; /* Hide overflow if content exceeds width */
}

.footer-column {
  flex: 1 1 auto;
  margin: 10px 20px;
  text-align: left; /* Align text to the left */
  min-width: 150px; /* Set minimum width */
}

.footer-column h4 {
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--navbar-text-color);
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  text-decoration: none;
  color: #555555;
  transition: color 0.3s ease;
}

.footer-column ul li a:hover {
  color: var(--navbar-hover-text);
}

/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid #e0e0e0;
  padding-top: 20px;
  width: 100%;
  text-align: center;
  color: #777777;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  /* Adjust padding */
  .footer {
    padding: 10px 30px;
  }

  .footer-company {
    margin-right: 30px;
  }

  .subscribe-image {
    width: 250px;
  }

  .subscribe-title {
    font-size: 26px;
  }

  .subscribe-text {
    font-size: 16px;
  }

  .subscribe-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .company-name {
    font-size: 20px;
  }

  .footer-column h4 {
    font-size: 16px;
  }

  .footer-column ul li a {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  /* Further reduce padding */
  .footer {
    padding: 10px 20px;
  }

  .footer-company {
    margin-right: 20px;
  }

  .subscribe-image {
    width: 200px;
  }

  .subscribe-title {
    font-size: 24px;
  }

  .subscribe-text {
    font-size: 14px;
  }

  .subscribe-button {
    font-size: 13px;
    padding: 8px 16px;
  }

  .company-name {
    font-size: 18px;
  }

  .footer-column h4 {
    font-size: 15px;
  }

  .footer-column ul li a {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  /* Further adjustments */
  .footer {
    padding: 10px 15px;
  }

  .footer-company {
    margin-right: 15px;
  }

  .subscribe-image {
    width: 150px;
  }

  .subscribe-title {
    font-size: 22px;
  }

  .subscribe-text {
    font-size: 13px;
  }

  .subscribe-button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .company-name {
    font-size: 16px;
  }

  .footer-column h4 {
    font-size: 14px;
  }

  .footer-column ul li a {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  /* Minimum adjustments */
  .footer {
    padding: 10px 10px;
  }

  .footer-company {
    margin-right: 10px;
  }

  .subscribe-image {
    width: 120px;
  }

  .subscribe-title {
    font-size: 20px;
  }

  .subscribe-text {
    font-size: 12px;
  }

  .subscribe-button {
    font-size: 11px;
    padding: 5px 10px;
  }

  .company-name {
    font-size: 14px;
  }

  .footer-column h4 {
    font-size: 13px;
  }

  .footer-column ul li a {
    font-size: 11px;
  }

  /* Allow horizontal scrolling if necessary */
  .footer-main,
  .footer-links {
    overflow-x: auto;
  }
}

/* Custom Scrollbar Styling */
/* For WebKit Browsers (Chrome, Safari) */
.footer-main::-webkit-scrollbar,
.footer-links::-webkit-scrollbar {
  height: 4px; /* Initial scrollbar height */
  background: transparent;
}

.footer-main::-webkit-scrollbar-track,
.footer-links::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.footer-main::-webkit-scrollbar-thumb,
.footer-links::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent black */
  border-radius: 2px;
  transition: background-color 0.3s ease, height 0.3s ease;
}

.footer-main:hover::-webkit-scrollbar-thumb,
.footer-links:hover::-webkit-scrollbar-thumb {
  background-color: #000; /* Solid black when hovering over the footer */
}

.footer-main::-webkit-scrollbar-thumb:active,
.footer-links::-webkit-scrollbar-thumb:active {
  background-color: #000; /* Solid black when scrolling */
}

/* For Firefox */
.footer-main,
.footer-links {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.footer-main:hover,
.footer-links:hover {
  scrollbar-color: #000 transparent;
}

/* Hide scrollbar in IE and Edge (optional) */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .footer-main,
  .footer-links {
    -ms-overflow-style: none;
  }
}
