/* src/Views/ResourcePage.css */
.resource-page {
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
}

.resource-navbar {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.nav-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
}

.nav-links li {
    margin: 0 10px;
}

.nav-links li a {
    text-decoration: none;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    color: #333;
    background-color: #e0e0e0;
    padding: 10px 20px;
    border-radius: 30px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links li a:hover {
    background-color: #c0c0c0;
    color: #000;
}

.content {
    text-align: center;
    margin-top: 50px;
}

.content h1 {
    font-size: 2rem;
    color: #333;
}

.content p {
    font-size: 1.2rem;
    color: #666;
}
