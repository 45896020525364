/* src/Components/Dropdown/Dropdown.css */

@import '../../styles/variables.css';

/* Sidebar styles */
.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  max-width: 80%;
  background-color: rgba(255, 255, 255, 0.9); /* Solid look with slight transparency */
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  padding: 80px 20px 20px 20px;
  overflow-y: auto;
  border-radius: 0 10px 10px 0;
}

.right-sidebar {
  right: 0;
  border-radius: 10px 0 0 10px;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  font-size: 36px; /* Increased size */
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: var(--icon-color);
  transition: color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
  color: var(--navbar-hover-text);
  transform: rotate(90deg); /* Optional rotation on hover */
}

/* Sidebar Title */
.sidebar-title {
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--primary-font);
  font-size: 24px;
  color: var(--navbar-text-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
}

.sidebar-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 2px;
  background-color: var(--navbar-hover-text);
  margin: 10px auto 0;
  border-radius: 1px;
}

/* Sidebar Content */
.sidebar-content {
  flex: 1;
}

/* Sidebar Items */
.sidebar-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  font-family: var(--primary-font);
  font-size: 16px;
  color: var(--navbar-text-color);
  background-color: rgba(255, 255, 255, 0.1); /* Glassy effect */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  width: 100%;
  text-align: left;
  margin-bottom: 15px; /* Increased spacing between buttons */
}

.sidebar-item:last-child {
  margin-bottom: 0;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
  color: var(--navbar-hover-text);
}

/* Icon in Sidebar Items */
.sidebar-item .icon {
  margin-right: 15px;
  font-size: 20px;
  color: var(--icon-color);
}

/* Specific Styles for Sign Out and Login Buttons */
.sign-out-button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
}

.login-button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
}

/* Tabs in Sidebar */
.sidebar-tabs {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.sidebar-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--button-bg);
  border: none;
  border-radius: 5px;
  color: var(--navbar-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-tab.active,
.sidebar-tab:hover {
  background-color: var(--button-hover-bg);
  transform: scale(1.05);
  color: var(--navbar-hover-text);
}

/* Icon in Tabs */
.sidebar-tab .icon {
  font-size: 20px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .nav-links {
    gap: 15px;
  }

  .nav-links li .nav-link {
    padding: 8px 12px;
    font-size: 14px;
  }

  .nav-logo {
    height: 45px;
  }

  .profile-icon,
  .profile-image {
    font-size: 32px;
    width: 32px;
    height: 32px;
  }

  .sidebar-title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .sidebar-item {
    font-size: 14px;
    padding: 12px 16px;
    margin-bottom: 12px;
  }

  .sidebar-item .icon {
    font-size: 18px;
    margin-right: 12px;
  }

  .sidebar-tabs {
    gap: 10px;
  }

  .sidebar-tab {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .nav-center {
    display: none;
  }

  .hamburger-menu {
    display: block;
    margin-right: 10px; /* Adjusted margin */
  }

  .mobile-nav-links {
    display: none; /* Now handled by the main sidebar */
  }

  .sidebar {
    width: 280px;
  }

  /* Adjust sidebar-tabs position for smaller screens */
  .sidebar-tabs {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .nav-links {
    gap: 10px;
  }

  .nav-links li .nav-link {
    padding: 6px 8px;
    font-size: 12px;
  }

  .nav-logo {
    height: 40px;
  }

  .sidebar {
    width: 250px;
  }

  .sidebar-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .sidebar-item {
    font-size: 12px;
    padding: 10px 14px;
    margin-bottom: 10px;
  }

  .sidebar-item .icon {
    font-size: 16px;
    margin-right: 10px;
  }

  .close-button {
    font-size: 32px;
  }

  .profile-icon,
  .profile-image {
    font-size: 28px;
    width: 28px;
    height: 28px;
  }

  .sidebar-tabs {
    gap: 8px;
  }

  .sidebar-tab {
    padding: 5px;
    font-size: 12px;
  }
}

/* Remove default link styles and ensure consistent styling */
.navbar a {
  color: var(--navbar-text-color);
  text-decoration: none;
}

.navbar a:visited {
  color: var(--navbar-text-color);
}

.navbar button {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
}

.navbar a,
.navbar button {
  transition: color 0.3s ease;
}
