/* src/App.css */

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #ffffff;
  color: #333333;
}

.App {
  text-align: center;
  padding-top: 130px; /* Adjust for fixed navbar and banner height */
  transition: padding-top 0.3s ease; /* Smooth transition when navbar height changes */
}

/* Ensure the App takes full height */
html, body, #root {
  height: 100%;
}
