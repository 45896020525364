/* src/Pages/HomePage.css */

@import '../../styles/variables.css';

/* Content container */
.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 0px; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Reset margin and padding */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--background-color, #ffffff); /* Using CSS variable */
}

/* Additional styling to reduce whitespace between sections */
.headline {
  margin-bottom: 5px; /* Reduced margin */
}

.investment {
  margin-top: 5px; /* Reduced margin */
}

/* Optional: Adjust Headline and Investment component styles */
