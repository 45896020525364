/* Container */
.logomation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center vertically */
    position: relative;
    height: 100%; /* Ensure it fills the parent container */
  }
  
  /* Animation Wrapper */
  .animation-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Animated Text */
  .animated-text {
    font-size: clamp(42px, 5vw, 42px);
    font-weight: 600;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    margin: 0;
    position: absolute; /* Overlay with logo and slogan */
    z-index: 1; /* Below logo and slogan */
  }
  
  .animated-text span {
    display: inline-block;
    opacity: 0;
    transform: translateY(100%);
    animation: fadeInUp 0.5s forwards;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Rotate Animations */
  .rotate-out {
    animation: rotateOut 0.7s forwards;
  }
  
  .rotate-in {
    animation: rotateIn 1.5s forwards;
  }
  
  @keyframes rotateOut {
    from {
      transform: rotateY(0deg);
      opacity: 1;
    }
    to {
      transform: rotateY(-90deg);
      opacity: 1;
    }
  }
  
  @keyframes rotateIn {
    from {
      transform: rotateY(90deg);
      opacity: 1;
    }
    to {
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
  
  /* Logo Image */
  .logo-image {
    width: 200px; /* Adjust as needed */
    height: auto;
    filter: invert(1); /* Invert colors to make the logo white */
    opacity: 1;
    position: absolute; /* Overlay with text and slogan */
    z-index: 2; /* Above text */
  }
  
  .explode-out {
    animation: explodeOut 2s forwards;
  }
  
  @keyframes explodeOut {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  /* Brand Slogan */
  .brand-slogan {
    font-family: 'Montserrat', sans-serif;
    font-size: clamp(34px, 4vw, 34px);
    font-weight: 400;
    color: #ffffff;
    opacity: 0;
    position: absolute; /* Overlay with text and logo */
    white-space: nowrap; /* Prevent text wrapping */
    margin: 0; /* Remove default margin */
    z-index: 3; /* On top */
  }
  
  .flash-in {
    animation: flashIn 0.5s forwards;
  }
  
  @keyframes flashIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  