/* OnboardingWizard.css */
.wizard-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .progress-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .progress-step {
    width: 23%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
  }
  
  .progress-step.active {
    background-color: #333333;
  }
  
  .step-count {
    text-align: center;
    margin-bottom: 20px;
    color: #555555;
  }
  
  .step {
    margin-bottom: 20px;
  }
  
  .step h2 {
    margin-bottom: 20px;
    color: #333333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333333;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #cccccc;
    border-radius: 30px;
    font-size: 16px;
  }
  
  .radio-group label {
    display: block;
    margin-bottom: 10px;
    color: #555555;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button-group button {
    padding: 10px 20px;
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .button-group button:hover {
    background-color: #555555;
  }
  
  .auth-choice button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .auth-choice p {
    margin-top: 20px;
    color: #555555;
  }
  
  .auth-choice h2 {
    margin-bottom: 10px;
    color: #333333;
  }
  
  .auth-choice button:hover {
    background-color: #555555;
  }
  
  .login-form,
  .signup-form {
    /* Add styles as needed */
  }
  
  .login-form p,
  .signup-form p {
    margin-top: 10px;
  }
  
  .login-form button,
  .signup-form button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #333333;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
  }
  
  .login-form button:hover,
  .signup-form button:hover {
    background-color: #555555;
  }
