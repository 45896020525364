/* Container for the table */
.data-table-container {
    padding: 20px;
    background-color: #f7f9fc; /* Light background */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Subtle shadow */
    margin-top: 20px;
    font-family: 'Poppins', sans-serif;
}

h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

/* The table itself */
.data-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.data-table th, .data-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #eaeaea;
}

/* Header styling */
.data-table th {
    background-color: #f3f4f6; /* Light gray header */
    color: #333;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
}

/* Cell styling */
.data-table td {
    font-size: 14px;
    color: #333;
}

/* Alternating row colors */
.data-table tr:nth-child(even) {
    background-color: #f9fafb;
}

/* Hover effect for rows */
.data-table tr:hover {
    background-color: #f1f1f1;
    transition: background-color 0.2s;
}

/* Add padding to table container */
.data-table-container {
    padding: 25px;
    background-color: #f5f6fa;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.data-table td, .data-table th {
    border-right: 1px solid #e2e2e2;
}

.data-table th:last-child, .data-table td:last-child {
    border-right: none;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .data-table th, .data-table td {
        font-size: 12px;
        padding: 10px;
    }
}
