/* src/Components/Carousel/Carousel.css */

/* Carousel Container */
.photo-carousel {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  cursor: grab;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background-color: #ffffff;
}

.photo-carousel::-webkit-scrollbar {
  display: none;
}

/* Image Container */
.carousel-image-container {
  position: relative;
  flex: none;
  width: calc(25% - 20px);
  overflow: hidden;
  border-radius: 15px;
}

/* Image Link */
.carousel-image-link {
  display: block;
  width: 100%;
  height: 100%;
}

/* Image Styling */
.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

/* Overlay with Title and Description */
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
}

/* Hover Effect for Desktop */
.carousel-image-container:hover .carousel-overlay {
  opacity: 1;
}

/* Always-On Overlay for Mobile Screens */
@media (max-width: 768px) {
  .carousel-overlay {
    opacity: 1;
  }
}

.overlay-title {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.overlay-description {
  font-size: 0.9em;
}

/* Navigation Dots */
.carousel-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.dot {
  width: 7px;
  height: 7px;
  background-color: #333333;
  border-radius: 50%;
  margin: 0 3px;
  cursor: pointer;
}

.dot.active {
  width: 10px;
  height: 10px;
  background-color: #000000;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .carousel-image-container {
    width: calc(33.3333% - 20px);
  }
}

@media (max-width: 768px) {
  .carousel-image-container {
    width: calc(50% - 20px);
  }
}

/* Dragging Active State */
.photo-carousel.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
