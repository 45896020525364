/* src/Pages/ShopPage.css */

@import '../../styles/variables.css';

.shop-page {
  padding: 100px 40px 40px 40px; /* Top padding accounts for fixed navbar */
  background-color: #f9f9f9;
  min-height: 100vh;
}

.shop-header {
  text-align: center;
  margin-bottom: 30px;
}

.shop-header h1 {
  font-family: var(--primary-font);
  font-size: 3rem;
  color: var(--navbar-text-color);
}

.breadcrumb {
  font-family: var(--primary-font);
  color: #888;
  font-size: 1.2rem;
  margin-top: 10px;
}

.breadcrumb a {
  color: #555;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

/* Categories */
.categories-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
  gap: 20px; /* Adds spacing between categories */
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  width: 120px; /* Adjust width of category */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.category-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.category-item:hover img {
  transform: scale(1.1);
}

.category-item span {
  font-family: var(--primary-font);
  font-size: 1.1rem;
  color: var(--navbar-text-color);
}

/* Product Grid */
.product-grid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.product-grid-header span {
  font-size: 1.2rem;
  color: #555;
  font-family: var(--primary-font);
}

.filters {
  display: flex;
  align-items: center;
}

.filters select {
  margin-left: 10px;
  padding: 8px;
  font-family: var(--primary-font);
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  transition: border-color var(--animation-duration) ease;
}

.filters select:hover,
.filters select:focus {
  border-color: var(--navbar-hover-text);
}

.products-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Auto adjusts */
  gap: 30px;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-item img {
  width: 150px; /* Consistent image size */
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.product-item:hover img {
  transform: scale(1.05);
}

.product-item .product-tag {
  font-size: 0.8rem;
  background-color: #f5a623;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  font-family: var(--primary-font);
}

.product-item .product-name {
  font-family: var(--primary-font);
  font-size: 1.1rem;
  margin-top: 10px;
  color: var(--navbar-text-color);
}

.product-item .product-price {
  font-family: var(--primary-font);
  font-size: 1rem;
  margin-top: 5px;
  color: #555;
}

.add-to-cart-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  font-family: var(--primary-font);
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-to-cart-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .shop-page {
    padding: 80px 20px 20px 20px; /* Adjust top padding for smaller navbar */
  }

  .products-container {
    grid-template-columns: repeat(2, 1fr); /* Adjust to 2 columns on smaller screens */
    gap: 20px;
  }

  .category-item {
    width: 100px;
    padding: 8px;
  }

  .category-item img {
    width: 80px;
    height: 80px;
  }

  .filters {
    flex-direction: column;
    align-items: flex-start;
  }

  .filters select {
    margin-left: 0;
    margin-top: 10px;
    width: 100%;
  }
}
