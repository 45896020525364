/* src/Components/AuthModal/AuthModal.css */

@import '../../styles/variables.css';

/* Overlay for the modal */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

/* Modal Container */
.auth-modal {
  background-color: var(--navbar-bg-color);
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 800px;
  position: relative;
  font-family: var(--primary-font);
}

/* Close Button */
.auth-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--icon-color);
  transition: color 0.3s ease;
}

.auth-close-button:hover {
  color: var(--navbar-hover-text);
}

/* Modal Body */
.modal-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

/* Image in Modal */
.modal-image {
  flex: 1;
  max-width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.modal-image:hover {
  transform: scale(1.05);
}

/* Form Container */
.form-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  min-width: 250px;
}

/* Heading */
.form-container h2 {
  margin-bottom: 20px;
  color: var(--navbar-text-color);
  text-align: center;
  transition: color 0.3s ease;
}

.form-container h2:hover {
  color: var(--navbar-hover-text);
}

/* Input Fields */
.form-container input {
  width: 100%;
  padding: 12px;
  margin: 10px 0 25px 0; /* Increased bottom margin for more spacing */
  border: 1px solid #ccc;
  border-radius: 30px;
  font-family: var(--primary-font);
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-container input:focus {
  border-color: var(--navbar-hover-text);
  outline: none;
}

/* Submit Button */
.form-container .auth-button {
  margin-top: 10px;
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  background-color: var(--navbar-text-color);
  color: #fff;
  cursor: pointer;
  font-family: var(--primary-font);
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.form-container .auth-button:hover {
  background-color: var(--navbar-hover-text);
  transform: translateY(-2px);
}

/* Switch Text */
.form-container .switch-text {
  margin-top: 30px; /* Increased margin-top for more spacing */
  text-align: center;
  color: var(--navbar-text-color);
  transition: color 0.3s ease;
}

.form-container .switch-text:hover {
  color: var(--navbar-hover-text);
}

/* Switch Button */
.form-container .switch-button {
  background: none;
  border: none;
  color: var(--navbar-hover-text);
  cursor: pointer;
  margin-left: 5px;
  font-family: var(--primary-font);
  font-size: 16px;
  transition: color 0.3s ease;
}

.form-container .switch-button:hover {
  text-decoration: underline;
}

/* Error Message */
.error {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .auth-modal {
    padding: 20px;
  }

  .modal-content {
    flex-direction: column;
    max-width: 90%;
  }

  .modal-body {
    flex-direction: column;
    gap: 10px;
  }

  .modal-image {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .form-container {
    padding: 0;
    width: 100%;
  }

  .form-container h2 {
    font-size: 1.5rem;
  }

  .form-container input {
    font-size: 14px;
    padding: 10px;
    margin: 10px 0 20px 0;
  }

  .form-container .auth-button {
    font-size: 14px;
    padding: 10px 16px;
  }

  .form-container .switch-text {
    font-size: 14px;
    margin-top: 25px;
  }

  .form-container .switch-button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .auth-modal {
    padding: 15px;
  }

  .modal-body {
    flex-direction: column;
    gap: 10px;
  }

  .modal-image {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }

  .form-container h2 {
    font-size: 1.3rem;
  }

  .form-container input {
    font-size: 14px;
    padding: 10px;
    margin: 8px 0 15px 0;
  }

  .form-container .auth-button {
    font-size: 14px;
    padding: 10px 16px;
  }

  .form-container .switch-text {
    font-size: 14px;
    margin-top: 20px;
  }

  .form-container .switch-button {
    font-size: 14px;
  }
}
