/* src/Components/Banner/Banner.css */

@import '../../styles/variables.css';

/* CSS Variables for Theming */
:root {
    --banner-bg-color: #ffffff; /* Same as navbar for consistency */
    --banner-text-color: #333333;
    --banner-accent-color: #6A0DAD;
    --icon-grape-color: #6A0DAD;
    --icon-beer-color: #F4A460;
    --banner-font: 'Montserrat', sans-serif;
    --banner-height: 60px; /* Same height as navbar */
    --banner-transition: background-color 0.3s ease;
  }
  
  /* Banner Styles */
  .banner {
    background-color: var(--banner-bg-color);
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* Removed rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full width */
    position: fixed;
    top: 70px; /* Positioned just below the navbar */
    left: 0;
    z-index: 999; /* Just below navbar */
    transition: var(--banner-transition);
  }
  
  .banner:hover {
    background-color: #e9ecef;
  }
  
  /* Ticker Container */
  .ticker-container {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  /* Ticker Effect */
  .ticker {
    display: flex;
    align-items: center;
    white-space: nowrap;
    animation: ticker 20s linear infinite;
    justify-content: center;
  }
  
  .ticker h1, .ticker p {
    font-family: var(--banner-font);
    margin-right: 15px;
    font-size: 1rem;
    color: var(--banner-text-color);
    white-space: nowrap;
  }
  
  .icon {
    font-size: 1.2rem;
    margin: 0 8px;
  }
  
  .grape-icon {
    color: var(--icon-grape-color);
  }
  
  .beer-icon {
    color: var(--icon-beer-color);
  }
  
  /* Keyframes for Ticker */
  @keyframes ticker {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Reduced Motion Preference */
  @media (prefers-reduced-motion: reduce) {
    .ticker {
      animation: none;
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .ticker h1, .ticker p {
      font-size: 0.9rem;
    }
  
    .icon {
      font-size: 1rem;
      margin: 0 6px;
    }
  
    .banner {
      top: 60px; /* Adjusted for smaller navbar height if necessary */
    }
  }
  
  @media (max-width: 480px) {
    .banner {
      height: auto;
      padding: 15px;
      top: 60px; /* Adjust if navbar height changes */
    }
  
    .ticker h1, .ticker p {
      font-size: 0.85rem;
    }
  
    .icon {
      font-size: 0.9rem;
      margin: 0 4px;
    }
  }
  