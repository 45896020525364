/* src/Pages/ArticlesPage/ArticlesPage.css */

.articles-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.articles-page h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
}

.articles-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.article-card {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  width: 300px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.article-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.article-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.article-content {
  padding: 15px;
  text-align: center;
  color: #555; /* Main text color */
}

.article-content h2,
.article-content h3,
.article-content h4 {
  color: #333;
}

.article-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.article-content p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Table styling */
.table-container {
  margin-bottom: 20px;
}

.grape-ales-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  color: #555;
}

.grape-ales-table thead {
  border-bottom: 2px solid #e0e0e0;
}

.grape-ales-table th,
.grape-ales-table td {
  padding: 6px 8px;
  text-align: left;
}

.grape-ales-table th {
  background-color: rgba(250, 250, 250, 0.8); /* More transparent header background */
  font-weight: 600;
}

.grape-ales-table tr:nth-child(even) {
  background-color: rgba(249, 249, 249, 0.8); /* More transparent row background */
}

.grape-ales-table tr:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Subtle semi-transparent hover effect */
}

.table-divider {
  width: 50%;
  height: 2px;
  background-color: #e0e0e0;
  margin: 20px auto; /* Centered under the table */
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 1rem;
  margin: 0 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.pagination button:hover {
  color: #0056b3;
}

.pagination button:disabled {
  color: #ccc;
  cursor: default;
}

.pagination .page-number {
  font-size: 1rem;
  margin: 0 5px;
  color: #555;
}

/* Chart styling */
.chart-container {
  margin: 40px 0;
}

.recharts-wrapper {
  margin: 0 auto;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

/* Modal Content */
.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: transparent;
  border-radius: 20px;
  overflow: hidden;
  max-height: 90vh;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

/* Adjusted Modal Content for Article5 */
.article5-modal {
  max-width: 1200px; /* Increase the max-width to 1200px */
}

/* Modal Background */
.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  z-index: 0;
}

.modal-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6);
}

/* Modal Text Content */
.modal-text-content {
  position: relative;
  z-index: 1;
  overflow-y: auto;
  max-height: 80vh;
  padding: 40px;
  color: #333;
  text-align: justify;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.modal-text-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

/* Close Button */
.close-button {
  position: absolute;
  top: 7px;
  right: 15px;
  font-size: 2rem;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 2;
}

.close-button:hover {
  color: #ccc;
}

/* Dotted Scrollbar */
.dotted-scrollbar {
  position: absolute;
  top: 250px;
  right: 20px;
  z-index: 2;
}

.dots-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.dots-container .dot {
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dots-container .dot.active {
  background-color: #fff;
  transform: scale(1.1);
}

.dots-container .dot {
  opacity: 0.3;
}

.dots-container .dot.active {
  opacity: 1;
}

/* Text Animations */
.modal-text-content p,
.modal-text-content h2,
.modal-text-content h3,
.modal-text-content h4 {
  opacity: 0;
  transform: translateY(20px);
  filter: blur(5px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, filter 0.6s ease-out;
}

.modal-text-content p.show,
.modal-text-content h2.show,
.modal-text-content h3.show,
.modal-text-content h4.show {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}

.modal-text-content h2,
.modal-text-content h3,
.modal-text-content h4 {
  margin-top: 40px;
  margin-bottom: 30px;
}

.modal-text-content p {
  line-height: 1.8;
  margin-bottom: 20px;
}

.modal-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 30px;
}

/* Article Navigation */
.article-navigation {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.article-navigation .previous-article-section,
.article-navigation .next-article-section {
  width: 48%;
}

.article-navigation .previous-article-section h3 {
  margin-bottom: 10px;
  text-align: left;
}

.article-navigation .next-article-section h3 {
  margin-bottom: 10px;
  text-align: right;
}

.article-navigation .previous-article-card,
.article-navigation .next-article-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 10px;
  transition: background 0.3s ease;
}

.article-navigation .previous-article-card:hover,
.article-navigation .next-article-card:hover {
  background: rgba(255, 255, 255, 1);
}

.article-navigation .previous-article-card {
  margin-right: auto;
}

.article-navigation .next-article-card {
  margin-left: auto;
}

.article-navigation .previous-article-card img,
.article-navigation .next-article-card img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.article-navigation .previous-article-card h4,
.article-navigation .next-article-card h4 {
  margin: 0;
  font-size: 1rem;
}

.article-navigation .previous-article-card p,
.article-navigation .next-article-card p {
  margin: 5px 0 0;
  font-size: 0.8rem;
  color: #666;
}

/* Responsive */
@media (max-width: 768px) {
  .articles-grid {
    flex-direction: column;
    align-items: center;
  }

  .article-card {
    width: 100%;
  }

  .modal-text-content {
    padding: 20px;
  }

  .close-button {
    top: 5px;
    right: 10px;
  }

  .dotted-scrollbar {
    top: 250px;
    right: 15px;
  }

  .article-navigation {
    flex-direction: column;
    align-items: center;
  }

  .article-navigation .previous-article-section,
  .article-navigation .next-article-section {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .article-navigation .previous-article-section h3,
  .article-navigation .next-article-section h3 {
    text-align: center;
  }

  .article-navigation .previous-article-card,
  .article-navigation .next-article-card {
    margin: 0 auto;
  }

  /* Responsive styling for table */
  .grape-ales-table th,
  .grape-ales-table td {
    font-size: 12px;
    padding: 4px 6px;
  }

  .article-content {
    padding: 10px;
  }

  .pagination button {
    font-size: 0.9rem;
  }

  .pagination .page-number {
    font-size: 0.9rem;
  }

  /* Adjusted Modal Content for Article5 on mobile */
  .article5-modal {
    max-width: 100%;
  }
}
