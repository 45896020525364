/* src/styles/variables.css */

:root {
  --navbar-bg-color: #ffffff; /* Solid background */
  --navbar-text-color: #333333;
  --navbar-hover-bg: rgba(0, 0, 0, 0.05);
  --navbar-hover-text: #000000;
  --dropdown-bg: rgba(255, 255, 255, 0.9);
  --dropdown-text: #333333;
  --dropdown-hover-bg: rgba(0, 0, 0, 0.05);
  --primary-font: 'Montserrat', sans-serif;
  --navbar-height: 70px;
  --navbar-transition: all 0.3s ease;
  --icon-color: #333333;
  --button-bg: rgba(255, 255, 255, 0.1); /* Glassy button background */
  --button-border: 1px solid rgba(255, 255, 255, 0.3);
  --button-hover-bg: rgba(255, 255, 255, 0.2);
  --cart-count-bg: #ff0000;
  --cart-count-text: #ffffff;
  --animation-duration: 0.5s;

  /* Footer specific variables */
  --footer-bg-color: var(--navbar-bg-color); /* Same as navbar */
  --footer-text-color: var(--navbar-text-color); /* Same as navbar */
  --footer-link-color: #333333; /* Changed to match navbar text color */
  --footer-link-hover-color: var(--navbar-hover-text);
  --footer-disclaimer-color: #9ca3af; /* Medium gray */
}
