/* src/Pages/UserProfile.css */

@import '../../styles/variables.css';

.profile-page {
  background-color: #f9f9f9;
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh; /* Ensure the page takes full height */
  display: flex;
  flex-direction: column;
}

.profile-container {
  max-width: 800px;
  width: 100%;
  margin: 100px auto 20px auto; /* Adjust top margin to account for navbar */
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Flex grow to push footer to the bottom if needed */
  flex-grow: 1;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-pic {
  margin-right: 20px;
}

.profile-pic-placeholder,
.profile-pic-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.profile-pic-placeholder {
  background-color: #ccc;
}

.profile-pic-img {
  object-fit: cover;
}

.profile-info {
  flex-grow: 1;
}

.profile-info h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.profile-info p {
  color: #777;
  margin-top: 5px;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.profile-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}

.profile-actions .save-button {
  background-color: #007bff;
}

.profile-details {
  background-color: #fff;
}

.about-section {
  margin-bottom: 20px;
}

.about-section h3 {
  margin-bottom: 10px;
  color: #333;
}

.about-section textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: none;
  min-height: 100px;
}

.additional-info p {
  margin-bottom: 10px;
}

.additional-info input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .profile-pic {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .profile-actions {
    flex-direction: column;
    align-items: center;
  }

  .profile-actions button {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
