/* src/Components/Navbar/Navbar.css */

@import '../../styles/variables.css';

/* Navbar Container */
.navbar.top-navbar {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center; /* Center the container */
  align-items: center;
  background-color: var(--navbar-bg-color); /* Solid background */
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: var(--navbar-height);
  transition: height var(--navbar-transition), padding var(--navbar-transition);
}

/* Navbar Content Container */
.navbar-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Navigation Sections */
.nav-left,
.nav-center,
.nav-right {
  display: flex;
  align-items: center;
}

/* Flex Distribution */
.nav-left {
  flex: 1;
  align-items: center;
}

.nav-center {
  flex: 2;
  justify-content: center;
}

.nav-right {
  flex: 1;
  justify-content: flex-end;
}

/* Logo */
.nav-logo {
  height: 50px;
  transition: transform 0.3s ease;
}

.nav-logo:hover {
  transform: scale(1.05);
}

/* Hamburger Menu */
.hamburger-menu {
  display: block; /* Always visible */
  font-size: 24px;
  color: var(--icon-color);
  cursor: pointer;
  margin-right: 10px; /* Reduced margin */
  transition: color 0.3s ease;
}

.hamburger-menu:hover {
  color: var(--navbar-hover-text);
}

/* Navigation Links */
.nav-links {
  display: flex;
  list-style: none;
  gap: 20px;
  align-items: center;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-links.mobile-open {
  display: none; /* Hide in mobile when menu is open */
}

.nav-links li .nav-link {
  padding: 10px 15px;
  font-family: var(--primary-font);
  font-size: 16px;
  color: var(--navbar-text-color);
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  position: relative;
}

.nav-links li .nav-link:hover {
  color: var(--navbar-hover-text);
}

/* Dropdown Arrow */
.dropdown-arrow {
  margin-left: 5px;
  font-size: 12px;
}

/* Shop Notification Badge */
.shop-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: var(--cart-count-bg);
  color: var(--cart-count-text);
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: bold;
}

/* Dropdown Toggle */
.dropdown-toggle {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-toggle:hover {
  color: var(--navbar-hover-text);
}

/* Profile Icon */
.profile-icon {
  font-size: 32px; /* Increased size */
  color: var(--icon-color);
  transition: transform 0.3s ease;
}

.profile-icon:hover {
  transform: scale(1.1);
}

/* Profile Image */
.profile-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.profile-image:hover {
  transform: scale(1.1);
}

/* Shopping Cart Icon */
.shopping-cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px; /* Adjusted margin */
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.shopping-cart-icon:hover {
  color: var(--navbar-hover-text);
  transform: scale(1.1);
}

/* Removed Tooltip Styling */

/* Sidebar Styles - Removed duplication from Dropdown.css */
.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 300px;
  max-width: 80%;
  background-color: rgba(255, 255, 255, 0.9); /* Solid look with slight transparency */
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1100;
  padding: 80px 20px 20px 20px; /* Top padding accounts for navbar height */
  overflow-y: auto;
  border-radius: 0 10px 10px 0;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  font-size: 36px; /* Increased size for visibility */
  position: absolute;
  top: 15px; /* Adjusted positioning */
  right: 20px;
  cursor: pointer;
  color: var(--icon-color);
  transition: color 0.3s ease, transform 0.3s ease;
  z-index: 1200; /* Ensure it's above other elements */
}

.close-button:hover {
  color: var(--navbar-hover-text);
  transform: rotate(90deg); /* Rotate animation on hover */
}

/* Sidebar Title */
.sidebar-title {
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--primary-font);
  font-size: 24px;
  color: var(--navbar-text-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
}

.sidebar-title::after {
  content: '';
  display: block;
  width: 60px;
  height: 2px;
  background-color: var(--navbar-hover-text);
  margin: 10px auto 0;
  border-radius: 1px;
}

/* Sidebar Content */
.sidebar-content {
  flex: 1;
}

/* Sidebar Items */
.sidebar-item {
  display: flex;
  align-items: center;
  padding: 20px 25px; /* Increased padding for better spacing */
  font-family: var(--primary-font);
  font-size: 18px; /* Increased size for readability */
  color: var(--navbar-text-color);
  background-color: rgba(255, 255, 255, 0.1); /* Glassy effect */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  width: 100%;
  text-align: left;
  margin-bottom: 15px; /* Space between buttons */
  position: relative;
}

.sidebar-item:last-child {
  margin-bottom: 0;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-2px);
  color: var(--navbar-hover-text);
}

/* Icon in Sidebar Items */
.sidebar-item .icon {
  margin-right: 15px;
  font-size: 24px; /* Increased size for better visibility */
  color: var(--icon-color);
}

/* Specific Styles for Sign Out and Login Buttons */
.sign-out-button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
}

.login-button {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 15px 20px;
}

/* Tabs in Sidebar */
.sidebar-tabs {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  justify-content: center; /* Center the tabs */
}

.sidebar-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: var(--button-bg);
  border: none;
  border-radius: 10px;
  color: var(--navbar-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-tab.active,
.sidebar-tab:hover {
  background-color: var(--button-hover-bg);
  transform: scale(1.05);
  color: var(--navbar-hover-text);
}

/* Icon in Tabs */
.sidebar-tab .icon {
  font-size: 24px; /* Increased size */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .navbar-container {
    max-width: 1200px;
  }
}

@media (max-width: 1024px) {
  .navbar-container {
    max-width: 1200px;
  }

  .nav-links {
    gap: 15px;
  }

  .nav-links li .nav-link {
    padding: 8px 12px;
    font-size: 14px;
  }

  .nav-logo {
    height: 45px;
  }

  .profile-icon,
  .profile-image {
    font-size: 32px; /* Increased size */
    width: 32px;
    height: 32px;
  }

  .sidebar-title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .sidebar-item {
    font-size: 16px; /* Increased from 14px */
    padding: 18px 22px; /* Increased padding */
    margin-bottom: 12px;
  }

  .sidebar-item .icon {
    font-size: 20px; /* Increased from 18px */
    margin-right: 12px;
  }

  .sidebar-tabs {
    gap: 10px;
    justify-content: center;
  }

  .sidebar-tab {
    padding: 12px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .nav-center {
    display: none;
  }

  .hamburger-menu {
    display: block;
    margin-right: 10px; /* Adjusted margin */
  }

  .mobile-nav-links {
    display: none; /* Now handled by the main sidebar */
  }

  .sidebar {
    width: 280px;
  }

  /* Adjust sidebar-tabs position for smaller screens */
  .sidebar-tabs {
    gap: 10px;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .nav-links {
    gap: 10px;
  }

  .nav-links li .nav-link {
    padding: 6px 8px;
    font-size: 12px;
  }

  .nav-logo {
    height: 40px;
  }

  .sidebar {
    width: 250px;
  }

  .sidebar-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .sidebar-item {
    font-size: 14px;
    padding: 16px 18px;
    margin-bottom: 10px;
  }

  .sidebar-item .icon {
    font-size: 18px;
    margin-right: 10px;
  }

  .close-button {
    font-size: 32px;
  }

  .profile-icon,
  .profile-image {
    font-size: 28px;
    width: 28px;
    height: 28px;
  }

  .sidebar-tabs {
    gap: 8px;
    justify-content: center;
  }

  .sidebar-tab {
    padding: 10px;
    font-size: 14px;
  }
}

/* Remove default link styles and ensure consistent styling */
.navbar a {
  color: var(--navbar-text-color);
  text-decoration: none;
}

.navbar a:visited {
  color: var(--navbar-text-color);
}

.navbar button {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
}

.navbar a,
.navbar button {
  transition: color 0.3s ease;
}
