/* Additional image */
.additional-image-container {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .additional-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
  }
  