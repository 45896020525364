/* src/Components/NewsletterPopup/NewsletterPopup.css */

.newsletter-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .newsletter-popup {
    background-color: #ffffff;
    border-radius: 10px;
    width: 90%;
    max-width: 600px;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .popup-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .corks-image {
    width: 150px;
    height: auto;
    margin-right: 20px;
  }
  
  .form-content {
    flex: 1;
  }
  
  .popup-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--navbar-text-color);
  }
  
  .popup-text {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555555;
  }
  
  .newsletter-form {
    display: flex;
    flex-direction: column;
  }
  
  .email-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .submit-button {
    padding: 10px;
    background-color: var(--button-bg);
    border: var(--button-border);
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: var(--button-hover-bg);
    color: var(--navbar-hover-text);
  }
  
  .form-message {
    margin-top: 10px;
    font-size: 14px;
    color: #ff0000;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .popup-content {
      flex-direction: column;
      align-items: center;
    }
  
    .corks-image {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100px;
    }
  
    .form-content {
      width: 100%;
    }
  }
  