/* Hero section */
.hero-section {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
}

/* Hero image */
.hero-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
  position: relative; /* Add this line */
  z-index: 1; /* Set lower z-index */
}

/* Hero content */
.hero-content {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center alignment */
  text-align: center;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2; /* Ensure it's above the hero image */
}

/* Background logo styling */
.background-logo {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center alignment */
  width: 40%; /* Responsive width */
  height: auto;
  background-image: url('../../assets/images/logo-transparent.png'); /* Ensure this path is correct */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5; /* Adjust opacity as needed */
  z-index: 1; /* Behind the text but above the hero image */
  filter: brightness(30); /* Keep the logo black */
}

/* Brand info */
.brand-info h1 {
  margin: 0;
  position: relative;
  z-index: 2; /* In front of the background logo */
  font-family: 'Poppins', sans-serif;
}

/* Brand name styling */
.brand-name {
  font-size: clamp(24px, 5vw, 48px);
  font-weight: 600;
  letter-spacing: -1px;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

/* Brand slogan styling */
.brand-slogan {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(16px, 3vw, 24px);
  font-weight: 400;
  margin-top: 5px;
  color: #ffffff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-content {
    padding: 0 20px; /* Add padding for smaller screens */
  }

  .background-logo {
    width: 60%; /* Adjust logo size on smaller screens */
  }
}
